import React, { useContext, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { isExpired, decodeToken } from "react-jwt";

import { UserContext } from './contexts/UserContext'

//import StartPage from './components/StartPage'
//import SnowClearingMap from './components/SnowClearingMap'
//import Pos from './components/Pos'

import BasePage from './components/BasePage'
import MissingAccessPage from './components/MissingAccessPage'
import LoginPage from './components/LoginPage'

const AppRouter = () => {
  const {user, userDispatch} = useContext(UserContext);
  const navigate = useNavigate()

  /////////////////////
  // Server interaction
  /////////////////////

  // Get system release info 
  const getSystemReleases = () => {
    axios.get(global.gConfig.SERVER_URL+'/api/config/release', {
      'headers': {
          'Content-Type': 'application/json'}
      })
      .then(res => {
        userDispatch({type: 'SET_RELEASE_INFO', releases: res.data})
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  }

  ////////////////////
  // useEffect area
  ////////////////////

  useEffect(() => {
    const token = localStorage.getItem('fjellsys');

    if(token) {
      //const tokenDec = jwt.decode(token);
      const myDecodedToken = decodeToken(token);
      const isMyTokenExpired = isExpired(token);


      if(!isMyTokenExpired) {
        //console.log("Token not expired", myDecodedToken)
        userDispatch({type: 'TOKEN_LOGIN', data: {myDecodedToken, token}})
      } else {
        //console.log("Token expired")
        //navigate("/login")
        // Move on to the signin page

      }  
    }
    
  }, [])

  // Get club information when the club id is set
  useEffect(() => {
    getSystemReleases();
  }, []);


  ////////////////////
  // Action handlers
  ////////////////////


  ///////////
  // UI part
  ///////////

  // Check if should redirect to login page
  const rootPage = user.accessToken ? (
    <Route exact path='/' element={<BasePage />} />
  ) : (
    <Route exact path='/' element={<LoginPage /> } />
  );


  return (
      <main>
        <Routes>
          {rootPage}  
        </Routes>   
      </main>
  )
}

export default AppRouter;


/*
      <main>
        <Routes>
          {rootPage}
          <Route path='/snowClearMap' element={<SnowClearingMap />} />       
          <Route exact path='/p' render={(props) => <Pos {...props} />} />       
          <Route path='/snowClearMap' component={SnowClearingMap} />
          <Route path='/startPage' render={(props) => <StartPage {...props} siteId='1' />} />    
        </Routes>   
      </main>

*/