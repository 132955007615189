import React, {useContext, useEffect, useState} from 'react'
import axios from 'axios'
import moment from "moment";
import "moment/locale/nb";

import { Typography } from "@mui/material"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import TextField from "@mui/material/TextField"
import Modal from "@mui/material/Modal"
import Backdrop from "@mui/material/Backdrop"
import Fade from "@mui/material/Fade"
import CardContent from "@mui/material/CardContent"
import {Dialog, DialogActions, DialogContent, DialogTitle, Stack} from '@mui/material';

import {splitt, getTimeFormat, getDateFormat, multiSplitt} from './utils/Library'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

import { UserContext } from '../contexts/UserContext'
import { OrderContext } from '../contexts/OrderContext'

moment.locale("nb");

const SnowClearingPanel = () => {

    const { user } = useContext(UserContext);
    const { orderContext, orderDispatch } = useContext(OrderContext);

    // Local state
    const [state, setState] = useState({
        showOrderDia: false    
    });

    const [locale] = useState('nb');
    const [orderDates, setOrderDates] = useState([
        {
          startDate: '',
          endDate: '',
          key: 'selection'
        }
      ]);    


    /////////////////////
    // Server interaction
    /////////////////////

    const orderSnowClearing = (order) => {
        //console.log(order)
        axios.post(global.gConfig.SERVER_URL+'/api/snowClearingOrders/sc/create/'+user.currentClubId, {
                order: order,
            },
            { 
            'headers': { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+user.accessToken
                }
            })
            .then (res => {
                console.log('Result - ',res);
                if(res.status===201) {
                    console.log('Success', res.status);
                }
              })
            .catch((err) => {
                console.log('Error', err);
            })      
    };


    ////////////////////
    // Hooks area
    ////////////////////

  
    ////////////////////
    // Action handlers
    ////////////////////

    //
    // Close SMS dialog box and clear message
    const handleClearClick = (e) => {
        setState({...state, showOrderDia: false });
    }

    // Save snowclearing orders
    const saveScOrder = (e) => {
        const order = {
            memberId: e.currentTarget.value,
            propertyId: orderContext.selectedCabinId,
            fromDate: getDateFormat(orderDates.startDate),
            fromTime: '18:00',
            toDate: getDateFormat(orderDates.endDate),
            comment: 'Bestilt av brøyter'
        }
        orderSnowClearing(order);
        setState({...state, showOrderDia: false })
        setOrderDates({...orderDates, startDate: new Date(), endDate: new Date(), key: 'selection'})
    }

    // Set start date
    const handleSetStartDate = (selectedDate) => {
        //console.log(selectedDate, selectedDate.toDate())
        if(selectedDate && selectedDate.isValid()) {
            setOrderDates({...orderDates, startDate: selectedDate})
        } else {
            setOrderDates({...orderDates, startDate: ''})
        }
        //console.log("Done!")
    }

    // Set end date
    const handleSetEndDate = (selectedDate) => {
        //console.log(selectedDate, selectedDate.toDate())
        if(selectedDate && selectedDate.isValid()) {
            setOrderDates({...orderDates, endDate: selectedDate})
        } else {
            setOrderDates({...orderDates, endDate: ''})
        }
    }


    ///////////
    // UI part
    ///////////  

    // Lookup correct caabin
    const cabin = orderContext.cabinList.find(c => c.id==orderContext.selectedCabinId) 
    const orderHeader = cabin ? ("Bestill brøyting for "+ cabin.firstname + " " + cabin.lastname) : null;

    // Snow clearing order - dialog window
    const orderPart = state.showOrderDia ? (
        <LocalizationProvider dateAdapter={AdapterMoment} locale={"nb"}>
            <div key={1}>
                <Dialog open={state.showOrderDia} onClose={(e) => setState({...state, showOrderDia: false})} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{orderHeader}</DialogTitle>
                    <DialogContent>
                        <div style={{display:"flex", flexDirection:"column", marginTop:"10px"}}>
                            <Stack spacing={2} >
                                <DatePicker
                                    disableToolbar
                                    required
                                    variant="inline"
                                    renderInput={(params) => <TextField {...params}/>}
                                    margin="normal"
                                    format="L"
                                    id="from_date"
                                    label="Ankomst"
                                    mask="__.__.____"
                                    value={(orderDates.startDate=='' || orderDates.startDate==null) ? null : orderDates.startDate}
                                    onChange={handleSetStartDate}
                                    autoOk={true}     
                                />
                                <DatePicker
                                    disableToolbar
                                    required
                                    variant="inline"
                                    renderInput={(params) => <TextField {...params}/>}
                                    margin="normal"
                                    format="L"
                                    id="to_date"
                                    label="Avreise"
                                    mask="__.__.____"
                                    value={(orderDates.endDate=='' || orderDates.endDate==null) ? null : orderDates.endDate}
                                    onChange={handleSetEndDate}    
                                    autoOk={true}      
                                />
                                <Button variant="contained" color="primary" type="submit" fullWidth id="btn" value={cabin.ownerId} onClick={(e) => saveScOrder(e)} > Bestill </Button>
                            </Stack>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </LocalizationProvider>
    ) : null

    const orderSnowClear = (user.content==='CABINS' && orderContext.selectedCabinId!==0 && !state.showOrderDia) ? (
        <Button variant="contained" color="primary" type="submit" fullWidth id="btn" value={cabin.id} onClick={(e) => setState({...state, showOrderDia: !state.showOrderDia})} >Bestill brøyting</Button>
    ) : null

    return (
        <div>
            {orderPart}
            {orderSnowClear}
        </div>
    )
}

export default SnowClearingPanel;
