import React, {useContext, useEffect, useState} from 'react'
import axios from 'axios'

import { Typography } from "@mui/material"
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import SelectedOrderPanel from './SelectedOrderPanel'
import SmsToAllPanel from './SmsToAllPanel'
import SmsToOnePanel from './SmsToOnePanel'
import SnowClearingPanel from './SnowClearingPanel'

import { UserContext } from '../contexts/UserContext'
import { OrderContext } from '../contexts/OrderContext'

const NavPanel = () => {

  const { user, userDispatch } = useContext(UserContext);
  const { orderContext, orderDispatch } = useContext(OrderContext);  
  const [counter, setCounter] = useState(0)

  // Refresh orders timer function
  const timer = (orderDate) => {
    if(orderDate) {
      getOrders(orderDate)
    }
  }


  /////////////////////
  // Server interaction
  /////////////////////
  const getOrders = (date) => {
    let orders = [];
    axios.get(global.gConfig.SERVER_URL+'/api/snowClearingOrders/'+user.currentClubId+'/'+date, { 
        'headers': { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+user.accessToken
            },
        })
        .then(res => {
            orders = res.data;
            //console.log("Update order list", orders);
            orderDispatch({ type: 'GET_ALL_ORDERS', orders: orders });
        });
  };
  

  ////////////////////
  // Hooks area
  ////////////////////
  useEffect(() => {
    const interval = setInterval(() => timer(orderContext.selectedDate), parseInt(global.gConfig.SCREEN_REFRESH_RATE));
    return () => {
      clearInterval(interval)
    }
  }, [orderContext.selectedDate])
  

  
  ////////////////////
  // Action handlers
  ////////////////////

  //
  // Selecte a club
  //  
  const handleSelectClub = (e) => {
    //console.log("Club change ", e.target.value)
    userDispatch({ type: 'SET_CURRECT_CLUB', clubId: e.target.value })
  }

  //
  // Show position switch
  //
  const handleShowPositionChange = (e) => {
    //console.log("Show position ", e.target.checked)
    const val = e.target.checked
    userDispatch({ type: 'SET_SHOW_POSITION', data: val })
  }

  //
  // Select day button handler
  //
  const handleDayButtonClick = (e) => {
    const selectedDate = e.currentTarget.value
    //console.log(selectedDate)
    orderDispatch({ type: 'SET_SELECTED_DATE', selectedDate: selectedDate});
    getOrders(selectedDate)
  }  

  //
  // Select Orders or Cabins
  //  
  const handleContentChange = (e) => {
    const newValue = e.target.value
    //console.log(newValue)
    userDispatch({type: 'SET_CONTENT', data: newValue})
  }


  //
  // Clear selection of a cabin
  const handleBack = (e) => {
    orderDispatch({ type: 'SET_SELECTED_CABIN', cabinId: 0 });
    orderDispatch({ type: 'SET_SELECTED_ORDER', orderId: 0 });
  }   


  ///////////
  // UI part
  ///////////  

  //
  // List of valid clubs
  //  
  const clubList = (user.clubs.length>0) ? (
    user.clubs.map(club => {
        return (
            <MenuItem key={club.id} value={club.id}>{club.clubName}</MenuItem>
        )
    })
  ) : (<MenuItem value={0}>Ingen tilgjengelige</MenuItem>)

  //
  // Club selection
  //
  const clubSelection = (user.clubs.length>0) ? (
    <div >
      <FormControl sx={{ m: 2, width: 220 }}>
        <InputLabel id="club-select-label"> Hytteforening </InputLabel>
        <Select
          labelId="club-select-label"
          id="club-select"
          value={user.currentClubId}
          onChange={handleSelectClub}
          input={<OutlinedInput label="Name" />}
          align="left"
        >
          {clubList}
        </Select>
      </FormControl>        
    </div>
  ) : (
      null
  )

  //
  // Week list
  //
  let row = 0;
  //console.log(orderContext)
  const dayButtons = (orderContext && orderContext.days.length && user.content==='ORDERS') ? (
      // Loop throug the days
      orderContext.days.map(day => {
          row++;  
          let dt = new Date(day.weekdate);
          let dtString = day.weekDay + ' / ' + dt.getDate() + '.' + (dt.getMonth()+1);
          let btnDateKey = ('0' + dt.getDate()).slice(-2) + '.' + ('0' + (dt.getMonth()+1)).slice(-2) + '.' + dt.getFullYear();
          return  (
              <div className="center weekButtons" key={row}>
                  <Button variant="contained" size="small" color="primary" fullWidth={true} value={btnDateKey} onClick={(e) => handleDayButtonClick(e)} > {dtString} - {day.cnt} </Button>
              </div>
          )
      })   
  ) : (
      null           
  );

  //
  // Select orders or cabins view
  //
  const ordersOrCabins = (user.currentClubId != 0) ? (
    <FormControl component="fieldset">
      <RadioGroup defaultValue={user.content} value={user.content} aria-label="content" name="constentRadio" onChange={handleContentChange}>
          <FormControlLabel value="ORDERS" size="small" control={<Radio />} label="Vis bestillinger" />
          <FormControlLabel value="CABINS" size="small" control={<Radio />} label="Vis hytter" />
      </RadioGroup>
    </FormControl>
  
  ) : ( null )


  //
  // Show the details about the selected cabin 
  //
  const cabin = orderContext.cabinList.find(a => a.id==orderContext.selectedCabinId)
  const basicCabinInfo = (orderContext && orderContext.selectedCabinId!=0 && user.content==='CABINS') ? (
    <Card className="sc-card" key={cabin.id}>
        <CardContent className="sc-cardContent">
            <Typography sx={{ variant: "subtitle2", fontSize: 12, fontWeight:"bold" }}>{cabin.address}</Typography>
            <Typography sx={{ variant: "subtitle2", fontSize: 10 }}>Eier: {cabin.firstname} {cabin.lastname} {cabin.companyName}</Typography>
            <Typography sx={{ variant: "subtitle2", fontSize: 10 }}>Mobil: {cabin.mobil}</Typography>
            <Typography sx={{ variant: "subtitle2", fontSize: 10 }}>Ekstraplasser: {cabin.extraParking}</Typography>
        </CardContent>    
    </Card>                   
  ) : null


  //
  // Show detailed arrival list for the selected day 
  //
  const filteredList = orderContext.orderList.filter(order => order.fromDate == orderContext.selectedDate);
  const sortedList = filteredList.sort((a,b) => (a.fromTime>b.fromTime) ? 1 : -1);

  // List elements
  const arrivalList = (orderContext.selectedOrderId===0 && orderContext.selectedDate && orderContext.orderList.length && sortedList.length>0 && user.content==='ORDERS') ? (
      sortedList.map(order => {
          return <Typography key={order.id} sx={{ variant: "subtitle2", fontSize: 10 }}>{order.fromTime+" - "+order.address}</Typography>
          })
  ) : (
    <Typography key={0} sx={{ variant: "subtitle2", fontSize: 10 }}>Ingen ankomster</Typography>
  );

  // Compose the full list and display in a card
  const arrivalCard = (orderContext.selectedOrderId===0 && orderContext.selectedDate && orderContext.orderList && user.content==='ORDERS') ? (
      <Card className="sc-card" key={-1}>
          <CardContent className="sc-cardContent">
            <Typography sx={{ variant: "subtitle2", fontSize: 12, fontWeight:"bold" }}>Ankomster {orderContext.selectedDate}</Typography>
            {arrivalList}
          </CardContent>
      </Card>
  ) : (
      null
  ); 

  //
  // Clear button
  const clearButton = user.content==='CABINS' && orderContext.selectedCabinId!==0 ? (
    <Button style={{marginTop: 5}} variant="contained" color="secondary" type="submit" fullWidth id="clearBtn" onClick={(e) => handleBack(e)} >Tilbake</Button>
  ) : null


  //
  // This is the main output
  //
  return (
    <header className="App-side">
    <div style={{display:"flex", flexFlow:"column", margin: "8px"}}>
      <Typography variant="subtitle2" >{user.companyName}</Typography>
      {clubSelection}
      <div>
        <FormControlLabel style={{align: "center"}} control={
          <Switch checked={user.showPosition} onChange={handleShowPositionChange} inputProps={{ 'aria-label': 'controlled' }}/>  
        } label="Vis posisjon" labelPlacement="start" size="small" />
      </div>
      {ordersOrCabins}
      {dayButtons}
      {basicCabinInfo}
      {arrivalCard}
      <SelectedOrderPanel />
      <SmsToAllPanel />
      <SmsToOnePanel />
      <SnowClearingPanel />
      {clearButton}
    </div>
    </header>
  )
}

export default NavPanel;
