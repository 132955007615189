const config = require('./config.json');
const REACT_APP_ENV = 'production'; 
//const REACT_APP_ENV = 'development'; 

//const defaultConfig = config.development;
//console.log(REACT_APP_ENV);
const environment = REACT_APP_ENV;
const environmentConfig = config[environment];

global.gConfig = environmentConfig;
//console.log(global.gConfig); 