// Load config first
import './config/config'

import AppRouter from './AppRouter'
import { ConfirmProvider } from 'material-ui-confirm';
//import { ConfirmDialogProvider } from 'react-mui-confirm'
import { createTheme, ThemeProvider } from '@mui/material'

import {orange} from '@mui/material/colors'

import UserContextProvider from './contexts/UserContext'
import OrderContextProvider from './contexts/OrderContext'


const App = () => {
  
  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: orange[500]
      }
    }
  })

  return (
    <UserContextProvider>
        <OrderContextProvider>
          <ThemeProvider theme={theme}>
             <ConfirmProvider>
              <AppRouter />
            </ConfirmProvider>
          </ThemeProvider>
        </OrderContextProvider>
      </UserContextProvider>
  )
}

export default App;



/*

    <UserContextProvider>
      <MapContextProvider>
        <OrderContextProvider>
          <ThemeProvider theme={theme}>
            <ConfirmDialogProvider>
              <AppRouter />
            </ConfirmDialogProvider>
          </ThemeProvider>
        </OrderContextProvider>
      </MapContextProvider>
    </UserContextProvider>


*/