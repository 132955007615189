import React, { createContext, useReducer } from 'react'
import UserReducer from '../reducers/UserReducer'

export const UserContext = createContext();

const UserContextProvider = (props) => {
    const [user, userDispatch] = useReducer(UserReducer , {
      userId: 0,
      name: '',
      username: '',
      companyId: 0,
      companyName: '',
      authError: null,
      signedIn: false,
      accessToken: null,
      lastLogin: null,
      currentLogin: null,
      pwdValidated: false,
      authError: null,
      currentClubId: 0,
      showPosition: false,
      content: 'ORDERS',
      clubs: []
    });

    return (
        <UserContext.Provider value={{ user, userDispatch }}>
            {props.children}
        </UserContext.Provider>
    ); 
}

export default UserContextProvider;