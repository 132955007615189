import React, {useContext, useEffect, useState} from 'react'
import axios from "axios"

import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import TextField from "@mui/material/TextField"
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

import {splitt, getTimeFormat, getDateFormat, multiSplitt} from './utils/Library'


import { UserContext } from '../contexts/UserContext'
import { OrderContext } from '../contexts/OrderContext'

const SelectedOrderPanel = () => {

  const { user, userDispatch } = useContext(UserContext);
  const { orderContext, orderDispatch } = useContext(OrderContext);

  // Local state
  const [state, setState] = useState({
    selectedOrder: null,
    showSmsAll: false,
    showSmsSingle: false,
    showOrderDia: false,
    smsMessage: ''        
  });


  /////////////////////
  // Server interaction
  /////////////////////

  const completeOrder = (order) => {
    const orderId = order.id;
    //console.log("Complete order ", order)
    axios.patch(global.gConfig.SERVER_URL+'/api/snowClearingOrders/confirm/'+user.currentClubId+'/'+orderId,
        {
            order: order
        },            
        {
            'headers': { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+user.accessToken
            },
        })
        .then(res => {
          orderDispatch({ type: 'COMPLETE_ORDER', order: order});
          setState({
            ...state,
            selectedOrder: null
          })
        })
        .catch(error => {
            console.log(error)
        });
  };    


  ////////////////////
  // Hooks area
  ////////////////////

  // Lookup the selected order
  useEffect(() => {
    const selectedOrderList = (orderContext.orderList && user.content === "ORDERS") ? orderContext.orderList.filter(order => order.id == orderContext.selectedOrderId) : null
    if(selectedOrderList && selectedOrderList.length) {
      setState({
        ...state,
        selectedOrder: selectedOrderList[0]
      })
    }
  }, [orderContext.selectedOrderId])

  // Debug
  /*useEffect(() => {
    console.log(state)
  }, [state])*/
  
  
  
  ////////////////////
  // Action handlers
  ////////////////////

  //
  // Complete order handler
  // 
  const handleClick = (e) => {
    completeOrder(state.selectedOrder)
  }

  //
  // Clear selection handler
  //
  const handleClearClick = (e) => {
      orderDispatch({type:'SET_SELECTED_CABIN', cabinId: 0});
      orderDispatch({ type: 'SET_SELECTED_ORDER', orderId: 0});
      setState({...state, selectedOrder: null, showSmsAll: false, showSmsSingle: false, showOrderDia: false});
  }

  //
  // Handle input in the return message Textfield
  //
  const handleTextInput = (e) => {
    const newText = e.currentTarget.value
    const modOrder = {...state.selectedOrder, returnMessage: newText}
    setState({
      ...state,
      selectedOrder: modOrder
    });
  }  

  ///////////
  // UI part
  ///////////  


  // Setup: Determine if an action button should be displayed 
  const showButton = state.selectedOrder && state.selectedOrder.completed!=1? (
    <Button style={{width: "80%", marginTop:10}} variant="contained" color="primary" type="submit" id="btn" value={state.selectedOrder.id} onClick={(e) => handleClick(e)} > Utført </Button>     
  ) : null

  const showCompletedIcon = state.selectedOrder && state.selectedOrder.completed==1 ? (
      <DoneOutlineIcon color='primary'></DoneOutlineIcon>
  ) : (
    null
  )

  // Clear selection button 
  const clearAct = state.selectedOrder ? (
    <Button style={{marginTop:10}} variant="contained" color="secondary" type="submit" fullWidth id="clearBtn" onClick={(e) => handleClearClick(e)} > Tilbake </Button>
  ) : null  
  
    
  // Setup: Display Order completion date and time
  const completionDate = state.selectedOrder && state.selectedOrder.completed == 1 ? (
    <Typography sx={{ variant: "subtitle2", fontSize: 8 }}>Utført: {getTimeFormat(state.selectedOrder.completedDate)}</Typography>
  ):(
      null
  );

  // Setup: Input or display response from snow clearing crew
  const commentField =  state.selectedOrder && state.selectedOrder.completed!=1 ? (
                        <TextField
                          id="respons"
                          style={{marginTop: 5, fontSize: 6}}
                          label="Melding fra brøyter"
                          placeholder=""
                          inputProps={{style: {fontSize: 10}}}
                          multiline
                          sx={{ m: 1, width: '70ch' }}
                          size="small"
                          variant="outlined"
                          onChange={(e) => handleTextInput(e)}
                          value={state.selectedOrder ? state.selectedOrder.returnMessage : ""}
                      /> ) : null

  // Setup: Ordered after the deadline
  const tooLateMessage = state.selectedOrder && state.selectedOrder.late === 1 ? (
    <Typography sx={{ variant: "subtitle2", fontSize: 10, color: "red" }}>Bestilt etter fristen</Typography>
  ):(
    null
  )

  // Show selected order
  const orderDetails = state.selectedOrder ? (
    <Card className="sc-card" style={{marginTop: 15}} key={state.selectedOrder.id}>
      <CardContent className="sc-cardContent">
        <Typography sx={{ variant: "subtitle2", fontSize: 12, fontWeight:"bold" }}>{state.selectedOrder.address}</Typography>
        <Typography sx={{ variant: "subtitle2", fontSize: 8 }}>Ankomst {state.selectedOrder.fromDate} {state.selectedOrder.fromTime}</Typography>
        <Typography sx={{ variant: "subtitle2", fontSize: 8 }}>Avreise {state.selectedOrder.toDate}</Typography>
        <Typography sx={{ variant: "subtitle2", fontSize: 8 }}>Mobil: {state.selectedOrder.mobil}</Typography>
        <Typography sx={{ variant: "subtitle2", fontSize: 8 }}>Eier: {state.selectedOrder.firstName} {state.selectedOrder.lastName} {state.selectedOrder.companyName}</Typography>
        <Typography sx={{ variant: "subtitle2", fontSize: 8 }}>Ekstraplasser: {state.selectedOrder.noExtraParking}</Typography>
        <Typography sx={{ variant: "subtitle2", fontSize: 8 }}>Bestilt: {getTimeFormat(state.selectedOrder.regTime)}</Typography>
        {tooLateMessage}
        {completionDate}
        {commentField}
        {showButton}
        {showCompletedIcon}
      </CardContent>
    </Card>    
  ) : null

  return (
    <div>
      {orderDetails}
      {clearAct}
    </div>
  )
}

export default SelectedOrderPanel;
































