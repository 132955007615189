import React, {useContext, useEffect, useState} from 'react'
import axios from 'axios'
import { Typography } from "@mui/material"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import TextField from "@mui/material/TextField"
import Modal from "@mui/material/Modal"
import Backdrop from "@mui/material/Backdrop"
import Fade from "@mui/material/Fade"
import CardContent from "@mui/material/CardContent"

import { UserContext } from '../contexts/UserContext'
import { OrderContext } from '../contexts/OrderContext'

const SmsToOnePanel = () => {

    const { user } = useContext(UserContext);
    const { orderContext, orderDispatch } = useContext(OrderContext);

    // Local state
    const [state, setState] = useState({
        showSmsSingle: false,
        smsMessage: ''        
    });


    /////////////////////
    // Server interaction
    /////////////////////

    const sendSmsToOne = (message) => {
        axios.post(global.gConfig.SERVER_URL+'/api/properties/sc/sms/single/'+user.currentClubId+'/'+orderContext.selectedCabinId, {
                message: message,
            },
            { 
            'headers': { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+user.accessToken
                }            
            })
            .then (res => {
                console.log('Result - ',res);
                if(res.status===201) {
                    console.log('Success', res.status);
                }
              })
            .catch((err) => {
                console.log('Error', err);
            })         
    };


    ////////////////////
    // Hooks area
    ////////////////////

  
    ////////////////////
    // Action handlers
    ////////////////////

    // 
    // Send SMS to one cabin owner
    //
    const sendPersonalSMS = (e) => {
        console.log("Sending sms ", state.smsMessage, " to owner of cabin ",orderContext.selectedCabinId);
        // Call server here
        sendSmsToOne(state.smsMessage);

        // Close window and reset text
        setState({...state, smsMessage: '', showSmsSingle:false });
    }

    //
    // Close SMS dialog box and clear message
    const handleClearClick = (e) => {
        setState({...state, showSmsSingle: false, smsMessage: '' });
    }

    //
    // Handle SMS input text
    const handleSMSTextInput = (e) => {
        setState({
            ...state,
            smsMessage: e.currentTarget.value
        })
    }



    ///////////
    // UI part
    ///////////  


    //
    // Handle SMS message button and window for a single cabin
    //
    const cabin = orderContext.cabinList.find(c => c.id==orderContext.selectedCabinId)
    const smsReceiver = cabin ? ("Send SMS til " + cabin.firstname + " " + cabin.lastname) : null
    const smsMessage = (user.content==='CABINS' && orderContext.selectedCabinId!=0 && state.showSmsSingle) ? (
        <React.Fragment key={1}>
            <Modal
                aria-labelledby="Send SMS til en hytte"
                aria-describedby="Send SMS til en hytte"
                open={state.showSmsSingle}
                onClose={(e) => setState({...state, showSmsSingle: false})}
            >
                <Fade in={state.showSmsSingle}>
                    <div>
                        <Card className="sc-card" key={2}>
                            <CardHeader title={smsReceiver} />
                            <CardContent className="sc-cardContent" style={{alignContent: "center"}}>                
                                <TextField
                                    id="smsPerson"
                                    label="SMS melding"
                                    placeholder=""
                                    multiline
                                    rows="4"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => handleSMSTextInput(e)}
                                    value={state.smsMessage}
                                />
                                <Button variant="contained" color="primary" type="submit" fullWidth id="btn" value={cabin.id} onClick={(e) => sendPersonalSMS(e)} > Send SMS </Button>
                            </CardContent>
                            <Button variant="contained" color="secondary" type="submit" fullWidth id="clearBtn" onClick={(e) => handleClearClick(e)} > Tilbake </Button>
                        </Card>
                    </div>
                </Fade>
            </Modal>
        </React.Fragment>
        ) : null
        

    const showButton = (user.content==='CABINS' && orderContext.selectedCabinId!==0 && !state.showSmsSingle) ? (
        <div>
            <Button variant="contained" color="primary" type="submit" fullWidth id="btn" value={1} style={{marginBottom: 5}} onClick={(e) => setState({...state, showSmsSingle: true})} > Send SMS </Button>
        </div>
    ) : null

    return (
        <div>
            {showButton}
            {smsMessage}
        </div>
    )
}

export default SmsToOnePanel;
