
import { decodeToken } from "react-jwt";

const UserReducer = (state, action) => {
  //console.log(action.data)
  switch(action.type) {
      case 'LOGIN_SUCCESS':
        const tokenDec = decodeToken(action.data.token);
        return ({
          ...state,        
          userId: tokenDec.id,
          name: tokenDec.name,
          username: tokenDec.username,
          companyId: tokenDec.companyId,
          companyName: tokenDec.companyName,
          currentLogin: tokenDec.currentLogin,
          lastLogin: tokenDec.lastLogin,
          pwdValidated: true,
          authError: null,
          signedIn: true,
          accessToken: action.data.token
        })
      case 'TOKEN_LOGIN':
        const tokenDecTok = decodeToken(action.data.token);
        return ({
          ...state,
          userId: tokenDecTok.id,
          name: tokenDecTok.name,
          username: tokenDecTok.username,
          companyId: tokenDecTok.companyId,
          companyName: tokenDecTok.companyName,
          currentLogin: tokenDecTok.currentLogin,
          lastLogin: tokenDecTok.lastLogin,
          pwdValidated: tokenDecTok.pwdValidated,
          authError: null,
          signedIn: true,
          accessToken: action.data.token
        })
      case 'LOGIN_ERROR':
        return ({
          ...state,
          authError: 'Login failed '+action.err,
          signedIn: false,
          accessToken: null,
          userId: 0,
          name: '',
          username: '',
          companyId: 0,
          companyName: '',
          clubs: [], 
          currentClubId: 0        
        })
      case 'SET_CLUBS':
        //console.log(action.clubs)
        return ({
          ...state,
          clubs: action.clubs
        })
      case 'SET_CURRECT_CLUB':
        //console.log(action.clubId)
        return ({
          ...state,
          currentClubId: action.clubId
        })
      case 'SET_SHOW_POSITION':
        //console.log(action.data)
        return ({
          ...state,
          showPosition: action.data
        })  
      case 'SET_CONTENT':
        //console.log(action.data)
        return ({
          ...state,
          content: action.data
        })                      
      default:
          return state;
  }
}

export default UserReducer;
