import React, {useContext, useEffect, useState} from 'react'
import axios from 'axios'
import { Typography } from "@mui/material"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import TextField from "@mui/material/TextField"
import Modal from "@mui/material/Modal"
import Backdrop from "@mui/material/Backdrop"
import Fade from "@mui/material/Fade"
import CardContent from "@mui/material/CardContent"

import { UserContext } from '../contexts/UserContext'
import { OrderContext } from '../contexts/OrderContext'

const SmsToAllPanel = () => {

    const { user } = useContext(UserContext);
    const { orderContext } = useContext(OrderContext);

    // Local state
    const [state, setState] = useState({
        showSmsAll: false,
        smsMessage: ''        
    });


    /////////////////////
    // Server interaction
    /////////////////////

    const sendSmsToAll = (message) => {
        //console.log(message)
        axios.post(global.gConfig.SERVER_URL+'/api/properties/sc/sms/all/'+user.currentClubId, {
                message: message,
            },
            { 
            'headers': { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+user.accessToken
                }
            })
            .then (res => {
                //console.log('Result - ',res);
                if(res.status===201) {
                    //console.log('Success', res.status);
                }
              })
            .catch((err) => {
                console.log('Error', err);
            })         
    };

    ////////////////////
    // Hooks area
    ////////////////////

  
    ////////////////////
    // Action handlers
    ////////////////////
  
    //
    // Send SMS to all cabins
    //
    const handleSendSMSToAllClick = (e) => {
        //console.log('Sender SMS: "', state.smsMessage, '" til alle hytteeiere');
        // Call server here
        sendSmsToAll(state.smsMessage);

        // Close message window and clear message
        setState({...state, showSmsAll: false, smsMessage: ''});
    }

    //
    // Clear selection handler
    const handleClearClick = (e) => {
        setState({...state, showSmsAll: false, smsMessage: ''});
    }

    //
    // Handle SMS input text
    const handleSMSTextInput = (e) => {
        setState({
            ...state,
            smsMessage: e.currentTarget.value
        })
    }



    ///////////
    // UI part
    ///////////  

    const smsMessage = (user.content==='CABINS' && orderContext.selectedCabinId===0 ) ? (
        state.showSmsAll  ? (
            <React.Fragment key={1}>
                <Modal
                    aria-labelledby="Send SMS til alle hyttene"
                    aria-describedby="Send SMS til alle hyttene"
                    //className={classes.modal}
                    open={state.showSmsAll}
                    onClose={(e) => setState({...state, showSmsAll: false})}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={state.showSmsAll}>
                        <div>
                            <Card className="sc-card" key={2}>
                                <CardHeader title="Send SMS til alle hyttene" />
                                <CardContent className="sc-cardContent" style={{alignContent: "center"}}>                
                                    <TextField
                                        id="smsToAll"
                                        label="SMS melding"
                                        placeholder=""
                                        multiline
                                        rows="4"
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => handleSMSTextInput(e)}
                                        value={state.smsMessage}
                                    />
                                    <Button variant="contained" color="primary" type="submit" fullWidth id="btn" value={1} onClick={(e) => handleSendSMSToAllClick(e)} > Send SMS </Button>
                                </CardContent>
                                <Button variant="contained" color="secondary" type="submit" fullWidth id="clearBtn" onClick={(e) => handleClearClick(e)} > Tilbake </Button>
                            </Card>
                        </div>
                    </Fade>
                </Modal>
            </React.Fragment>
        ) : (
            null
        )
    ) : (
        null
    )

    const showButton = user.content==='CABINS' && orderContext.selectedCabinId===0 && !state.showSmsAll ? (
        <Button variant="contained" color="primary" type="submit" fullWidth id="btn" value={1} style={{margin: "5px"}} onClick={(e) => setState({...state, showSmsAll: true})} > SMS til alle </Button>
    ) : null



    return (
        <div>
            {showButton}
            {smsMessage}
        </div>
    )
}

export default SmsToAllPanel;
