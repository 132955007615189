import React, { useState, useEffect, useContext } from 'react'
import { Typography, Card, CardActionArea, CardContent, CardActions, TextField, Button, MenuItem } from "@mui/material"
import axios from 'axios'
import './../App.css'
import md5 from "md5"
import { isExpired, decodeToken } from "react-jwt";

import background from '../images/bckground1.jpg'
import { UserContext } from './../contexts/UserContext'

const LoginPanel = () => {

  const [userInfo, setUserInfo] = useState({
    id: null,
    fullName: null,
    companyId: '', 
    companyName: null,   
    username: '',
    password: '',
    token: '',
    pwdValidated: false
  })

  const {user, userDispatch} = useContext(UserContext);

  /////////////////////
  // Server interaction
  /////////////////////


  const signIn = (credentials) => {
      const userSigninInfo = {...credentials, password: md5(credentials.password)}
      axios.post(global.gConfig.SERVER_URL+'/api/service/secureLogin', { 
          'headers': { 
                  'Content-Type': 'application/json' 
                  },
          //clubId: clubId,
          userInfo: userSigninInfo
      })
      .then(res => {
          if(res.status===200) {
              localStorage.setItem('fjellsys', res.data.token);
              const tokenDec = decodeToken(res.data.token);
              //console.log(res.data)
              //console.log(tokenDec)

              // Store access token
              userDispatch({type: 'LOGIN_SUCCESS', data: res.data})
          }
      })
      .catch((err) => {
          console.log('Error', err);
          userDispatch({type: 'LOGIN_ERROR', err: err})
      })        
    };      
 

  ////////////////////
  // Hooks area
  ////////////////////


  
  ////////////////////
  // Action handlers
  ////////////////////

  const handleUserName = (e) => {
    setUserInfo({...userInfo, username: e.target.value})
  }

  const handlePassword = (e) => {
    setUserInfo({...userInfo, password: e.target.value})
  }

  // Handles "Login" button click events
  const handleLogin = () => {
    signIn(userInfo)
  }

  const handleKeyDown = (e) => {
    if(e.key==='Enter') {
      handleLogin()
    }
  }



  ///////////
  // UI part
  ///////////  


  const loginDisabled = userInfo.username && userInfo.password ? null : 'disabled'; 

  const loginBtn = userInfo.signedIn ? (
    <Typography>Login OK!</Typography>
  ):(
    <Button color="primary" variant="outlined" fullWidth onClick={() => handleLogin()} > Login </Button>
  )
  const errorTxt = userInfo.result==-1 ? (<Typography color="secondary">Innloggingsfeil</Typography>) : (null)
  

  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <header className="App-header">
        <Typography variant="h1">Fjellsys</Typography>
        <Card>
          <CardContent>
            <div>
              <div style={{display:"flex", flexFlow:"row"}}>
                <TextField sx={{padding:"5px", width:"300px"}} required autoFocus id="username" value={userInfo.username} label="Brukernavn" onChange={(e) => handleUserName(e)} onKeyDown={(e) => handleKeyDown(e)} />
                <TextField sx={{padding:"5px", width:"300px"}} required autoFocus id="password" value={userInfo.password} type="password" label="Passord" onChange={(e) => handlePassword(e)} onKeyDown={(e) => handleKeyDown(e)} />
              </div>
              <div>
                {/*qrImgTag*/}
              </div>
              {/*code*/}
              {loginBtn}
            </div>
          </CardContent>
        </Card>        
      </header>
    </div>


  )
}

export default LoginPanel;

