import React, {useContext, useEffect, useState} from 'react'
import L from 'leaflet'

import { splitt, multiSplitt } from './utils/Library'
import { UserContext } from '../contexts/UserContext'
import { OrderContext } from '../contexts/OrderContext'

const MapPanel = () => {

  const { user } = useContext(UserContext);
  const { orderContext, orderDispatch } = useContext(OrderContext);

  // Local state
  const [state, setState] = useState({
    mapHolder: null,
    blockLineLayerGroup: null,
    cabinLayerGroup: null,
    orderLayerGroup: null,
    tractorLayerGroup: null,
    zoom: parseInt(global.gConfig.ZOOM)
});

  // Icons
  let greenIcon = L.icon({
    iconUrl: require("../images/Ball-Right-Green.png"),
    iconSize: [38, 38],
    iconAnchor: [20, 40],
    popupAnchor: [0, 0]        
  });        
  let redIcon = L.icon({
      iconUrl: require("../images/Red-Marker.png"),
      iconSize: [38, 38],
      iconAnchor: [20, 40],
      popupAnchor: [0, 0]        
  });        
  let purpleIcon = L.icon({
      iconUrl: require("../images/Purple-Marker.png"),
      iconSize: [38, 38],
      iconAnchor: [20, 40],
      popupAnchor: [0, 0]        
  });        
  let cabinIcon = L.icon({
      iconUrl: require("../images/cabinTransparent.png"),
      iconSize: [38, 38],
      iconAnchor: [20, 40],
      popupAnchor: [0, 0]        
  });                

  // Tractor marker
  let tractorIcon = L.icon({
      iconUrl: require("../images/circleMarker.png"),
      iconSize: [30, 30],
      iconAnchor: [15, 15],
      popupAnchor: [-3, -76]
  });


  // Show location timer function 
  const locationTimer = (showPosition) => {
    console.log("Show location")
  }

  /////////////////////
  // Server interaction
  /////////////////////




  ////////////////////
  // Hooks area
  ////////////////////

  // For debug purposes only
  /*useEffect(() => {
    console.log(state)
  }, [state])*/
  

  // Map setup
  useEffect(() => {
    if(user.currentClubId!=0) {

        // Find club information
        const club = user.clubs.find(club => club.id==user.currentClubId)
        const mapCenter = splitt(club.mapCenter);

        // Map update or setup
        if(state.mapHolder) {
            // Map is already initialized ==> Update center and location
            state.mapHolder.setView(mapCenter[0], state.zoom)
        } else {
            // Map is not initialized ==> Initialize map 
            // Setup map - this creates the map instance
            const map = L.map('snowClearingMap').setView(mapCenter[0], state.zoom);
            L.tileLayer(global.gConfig.MAP_URL, {
                        attribution: global.gConfig.ATTRIBUTION,
                        maxZoom: parseInt(global.gConfig.MAX_ZOOM)
                    }).addTo(map);
            const blockLineLayerGroup = L.layerGroup().addTo(map); 
            const cabinLayerGroup = L.layerGroup();
            const orderLayerGroup = L.layerGroup().addTo(map); 
            const tractorLayerGroup = L.layerGroup();

            // Use GeoLocation / GPS position
            //map.locate({watch: true, setView: true, maximumAge: 5000 });
            //map.on('locationfound', onLocationFound);
            //map.on('locationerror', onLocationError);

            // Save map and layers to local state for convenience
            setState({
                ...state,
                mapHolder: map,
                blockLineLayerGroup: blockLineLayerGroup,
                cabinLayerGroup: cabinLayerGroup,
                orderLayerGroup: orderLayerGroup,
                tractorLayerGroup: tractorLayerGroup
            });
        }
    }
  }, [user.currentClubId]);


  // Control which map layers to show
  useEffect(() => {
    //console.log(user.content, state.map, state.orderLayerGroup)
    // Show order layer group
    if(state.mapHolder && state.orderLayerGroup && user.content === 'ORDERS') {
      state.cabinLayerGroup.removeFrom(state.mapHolder)
      state.orderLayerGroup.addTo(state.mapHolder)
    }
    // Show cabin layer group
    if(state.mapHolder && state.cabinLayerGroup &&  user.content === 'CABINS') {
      state.orderLayerGroup.removeFrom(state.mapHolder)
      state.cabinLayerGroup.addTo(state.mapHolder)
    }
  
  }, [user.content])

  // Start location timer
  useEffect(() => {
    const interval = setInterval(() => locationTimer(user.showPosition), parseInt(global.gConfig.SCREEN_REFRESH_RATE));
    return () => {
      clearInterval(interval)
    }
  }, [user.showPosition])
  
  
  // GPS locator
  // Not ready for use
  /*useEffect(() => {
    console.log(state.tractorLayerGroup)
    // Use GeoLocation / GPS position
    if(state.mapHolder) {
      //map.locate({watch: true, setView: true, maximumAge: 5000 });
      state.mapHolder.on('locationfound', onLocationFound);
      state.mapHolder.on('locationerror', onLocationError);      
      state.mapHolder.locate({watch: true, setView: true, timeout:1000, maximumAge: 0, enableHighAccuracy: true });
    }
  }, [state.mapHolder, state.tractorLayerGroup])*/

  
  ////////////////////
  // Action handlers
  ////////////////////

  // Mark a snow clearing order as completed
  const setSelectedOrder = (orderId) => {
    orderDispatch({ type: 'SET_SELECTED_ORDER', orderId: orderId});
  };

  //
  // Show GPS location
  // Called from the map.watch function triggered 
  //
  // Not yet ready for use
  /*const onLocationFound = (e) => {
    let radius = e.accuracy/2;
    console.log(radius, e.latlng, state.tractorLayerGroup);
    if(e.latlng && state.tractorLayerGroup) {
      state.tractorLayerGroup.clearLayers();
      L.circle(e.latlng, 0.3).addTo(state.tractorLayerGroup);
    }
  }    

  const onLocationError = (e) => {
    console.log(e.message);
  }*/


  ///////////
  // UI part
  ///////////  

  //
  // Blockage lines on the map
  //
  const clrBlockLayer = state.blockLineLayerGroup ? state.blockLineLayerGroup.clearLayers() : null;
  const blockLines = (state.blockLineLayerGroup) ? (
    orderContext.blockLines.map(bline => {
        const line = splitt(bline.coordinates)
        var polyline = L.polyline(line, {color: 'red'}).addTo(state.blockLineLayerGroup);
    })
  ) : (
    null
  )

  //
  // Cabin markers on the map ==> set up cabin layer
  // First clear out old content
  //
  const clrCabinLayer = state.cabinLayerGroup ? state.cabinLayerGroup.clearLayers() : null;
  const cabinMarkers = (orderContext.cabinList.length && user.content==='CABINS') ? (
    // Loop throug all orders
    orderContext.cabinList.map(cabin => {
        // Marker for each cabin on the map with an action handler to set selected cabin
        if(cabin.latitude && cabin.longitude && state.mapHolder && state.cabinLayerGroup) {
            let m = L.marker([cabin.latitude, cabin.longitude], {icon: cabinIcon} ).addTo(state.cabinLayerGroup).on('click', (e) => {
                orderDispatch({type:'SET_SELECTED_CABIN', cabinId: e.target.props.id});
                //console.log('Valgt ', e.target.props.id);
            });
            m.props = {id: cabin.id};
        } 

        // Mark snow clearing area
        if(state.mapHolder && state.cabinLayerGroup) {
          // Permanent places
          if(cabin.polyPerm) {
              let latlngP = cabin.polyPerm;
              let newlatlngP = multiSplitt(latlngP);
              let polygonPerm = L.polygon(newlatlngP, {color: 'blue'}).bindPopup('Faste plasser').addTo(state.cabinLayerGroup);
          }
          // Extra places
          if(cabin.polyExtra) {
              let latlngE = cabin.polyExtra;
              let newlatlngE = multiSplitt(latlngE);
              let polygonExtra = L.polygon(newlatlngE, {color: 'purple'}).bindPopup('Ekstraplasser').addTo(state.cabinLayerGroup);
          }
      }

    })
  ) : (null)


  //
  // Order markers on the map ==> set up order layer
  // First clear out old content
  //
  const clrOrderLayer = state.orderLayerGroup ? state.orderLayerGroup.clearLayers() : null;
  const orderMarkers = (orderContext.orderList.length && user.content==='ORDERS') ? (
    // Loop throug all orders
    orderContext.orderList.map(order => {

      //console.log(order)

      // Choose icon based on status
      let icon = redIcon;
      if(order.late === 1) { // Ordered too late
          icon = purpleIcon;
      }
      if(order.completed === 1) { // Order completed
          icon = greenIcon;
      }

      // Marker for each order on the map
      if(order.lat && order.lng && state.mapHolder && state.orderLayerGroup) {
          let m = L.marker([order.lat, order.lng], {icon: icon} ).addTo(state.orderLayerGroup).on('click', (e) => {
              setSelectedOrder(e.target.props.id);
              setState({
                  ...state,
                  returnMessage: ''
              })
          });
          m.props = {id: order.id};
      } 
      // Mark snow clearing area
      if(state.mapHolder && state.orderLayerGroup) {
          // Permanent places
          if(order.polyPerm) {
              let latlngP = order.polyPerm;
              let newlatlngP = multiSplitt(latlngP);
              let polygonPerm = L.polygon(newlatlngP, {color: 'blue'}).bindPopup('Faste plasser').addTo(state.orderLayerGroup);
              //var seeArea = L.GeometryUtil.geodesicArea(polygonPerm.getLatLngs()[0]); 
              //console.log(seeArea);
          }
          // Extra places
          if(order.polyExtra) {
              let latlngE = order.polyExtra;
              let newlatlngE = multiSplitt(latlngE);
              let polygonExtra = L.polygon(newlatlngE, {color: 'purple'}).bindPopup('Ekstraplasser').addTo(state.orderLayerGroup);
          }
      }
    })
  ) : null


  return (
    <div>
      <div id="snowClearingMap" style={{height: "100vh", width: "100vwx"}}></div>
    </div>
  )
}

export default MapPanel;
