import React, {useContext, useEffect} from 'react'
import axios from 'axios'
import { Typography } from "@mui/material"

import NavPanel from './NavPanel'
import MapPanel from './MapPanel'

import { UserContext } from '../contexts/UserContext'
import { OrderContext } from '../contexts/OrderContext'

const BasePage = () => {

  const { user, userDispatch } = useContext(UserContext);
  const { orderContext, orderDispatch } = useContext(OrderContext);


  /////////////////////
  // Server interaction
  /////////////////////

  // Given the operator that is logged in, get all clubs
  const getAllClubs = (companyId) => {
    axios.get(global.gConfig.SERVER_URL+'/api/snowClearingOrders/clubs/get/all/'+companyId, { 
        'headers': { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+user.accessToken
            },
        })
        .then(res => {
            //console.log(res.data)
            userDispatch({ type: 'SET_CLUBS', clubs: res.data });
            userDispatch({ type: 'SET_CURRECT_CLUB', clubId: res.data[0].id });
        })
        .catch(error => {
            orderDispatch({ type: 'DATA_LOAD_FAILED', dataLoadFailed: true, dataType: "Clubs" });
        });    
  }

  // Get the overview for the coming week
  const getWeekList = (currentClubId) => {
    let days = [];
    axios.get(global.gConfig.SERVER_URL+'/api/snowClearingOrders/weekOverview/'+currentClubId, { 
        'headers': { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+user.accessToken
            },
        })
        .then(res => {
            days = res.data;
            orderDispatch({ type: 'GET_WEEK_LIST', days: days });
        })
        .catch(error => {
            orderDispatch({ type: 'DATA_LOAD_FAILED', dataLoadFailed: true });
        });
  };

  // Get all cabins
  const getAllCabins = (currentClubId) => {
    axios.get(global.gConfig.SERVER_URL+'/api/properties/sc/'+currentClubId, { 
        'headers': { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+user.accessToken
            },
        })
        .then(res => {
            orderDispatch({ type: 'SET_CABINS', cabins: res.data });
        })
        .catch(error => {
            orderDispatch({ type: 'DATA_LOAD_FAILED', dataLoadFailed: true });
        });    
  };

  // Get all block lines for the map
  // BasePage
  const getAllScBlockLines = (currentClubId) => {
    axios.get(global.gConfig.SERVER_URL+'/api/snowClearingOrders/map/block/sc/all/'+currentClubId, { 
        'headers': { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+user.accessToken
            },
        })
        .then(res => {
            //console.log(res.data)
            orderDispatch({ type: 'SET_SC_BLOCK_LINES', cabins: res.data });
        })
        .catch(error => {
            orderDispatch({ type: 'DATA_LOAD_FAILED', dataLoadFailed: true, dataType: "Blocklines" });
        });    
  }


  ////////////////////
  // Hooks area
  ////////////////////

  // For debug purposes
  /*useEffect(() => {
    console.log(user)
  },[user])*/

  // For debug purposes
  /*useEffect(() => {
    console.log(orderContext)
  },[orderContext])*/


  useEffect(() => {
    if(user.accessToken) {
      getAllClubs(user.companyId)
    }
  }, [user.accessToken])

  useEffect(() => {
    if(user.currentClubId!=0 && user.accessToken) {
      // Get week overview
      getWeekList(user.currentClubId)
      getAllCabins(user.currentClubId)
      getAllScBlockLines(user.currentClubId)
    }
  }, [user.currentClubId])
  
  
  ////////////////////
  // Action handlers
  ////////////////////


  ///////////
  // UI part
  ///////////  

  return (
    <div style={{display:"flex", flexFlow:"row"}} className="App">
      <div>
        <NavPanel/>
      </div>
      <div style={{width: "100%"}}>
        <MapPanel />
      </div>
    </div>
  )
}

export default BasePage;
